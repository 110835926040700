// src/components/ChangeOrderButtonGroup.js
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ArrowUpShort from './Icons/ArrowUpShort';
import ArrowDownShort from './Icons/ArrowDownShort';




function ChangeOrderButtonGroup() {

    const UpButtonPushed = () => {
      console.log('UpButtonPushed');
    };

    const DownButtonPushed = () => {
      console.log('DownButtonPushed');
    };

  return (
    <ButtonGroup aria-label="Basic example">
      <Button variant="primary" size="sm" onClick={UpButtonPushed}><ArrowUpShort /></Button>
      <Button variant="primary" size="sm" onClick={DownButtonPushed}><ArrowDownShort /></Button>
    </ButtonGroup>
  );
}

export default ChangeOrderButtonGroup;