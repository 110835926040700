// src/components/SearchFromCustomers.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Form, Container } from 'react-bootstrap';
import { fetchCustomersData } from '../features/actions/fetchCustomersData';


const SearchFromCustomers = ({ onSelectCustomer }) => {
  const dispatch = useDispatch();
  const customersData = useSelector((state) => state.data.customersData);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  useEffect(() => {
    dispatch(fetchCustomersData());
  }, [dispatch]);

  const filteredCustomers = customersData.filter(
    (customer) =>
      customer.nimi.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.ytunnus.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    // Ehdotetaan ensimmäistä asiakasta, joka vastaa hakuehtoja
    const firstSuggestion = filteredCustomers.find(
      (customer) =>
        customer.nimi.toLowerCase().includes(inputValue.toLowerCase()) ||
        customer.ytunnus.toLowerCase().includes(inputValue.toLowerCase())
    );

    setSelectedSuggestion(firstSuggestion);
  };

  const handleSuggestionClick = () => {
    // Kun käyttäjä klikkaa ehdotusta, valitaan asiakas ja nollataan ehdotus
    if (selectedSuggestion) {
      onSelectCustomer(selectedSuggestion);
      setSearchTerm('');
      setSelectedSuggestion(null);
    }
  };

  return (
    <Container>
      <h2>Search Customers</h2>
      <Form>
        <Form.Group controlId="formSearch">
          <Form.Control
            type="text"
            placeholder="Search by name or Y-tunnus"
            value={searchTerm}
            onChange={handleInputChange}
          />
          {/* Näytetään ehdotus input-kentän alla */}
          {selectedSuggestion && (
            <ListGroup.Item
              key={selectedSuggestion.asiakasid}
              onClick={handleSuggestionClick}
              style={{ cursor: 'pointer', border: '1px solid #ccc', borderRadius: '4px', marginTop: '5px' }}
            >
              {selectedSuggestion.nimi} - Y-tunnus: {selectedSuggestion.ytunnus}
            </ListGroup.Item>
          )}
        </Form.Group>
      </Form>
      <ListGroup>
        {/* Näytetään suodatetut asiakkaat */}
        {filteredCustomers.map((customer) => (
          <ListGroup.Item
            key={customer.asiakasid}
            onClick={() => onSelectCustomer(customer)}
            style={{ cursor: 'pointer' }}
          >
            {customer.nimi} - Y-tunnus: {customer.ytunnus}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default SearchFromCustomers;
