// src/components/SelectedUser.js
import React from 'react';
import { useSelector } from 'react-redux';

const SelectedUser = () => {
  // Käytä useSelectoria hakeaksesi valitun käyttäjän tiedot
  const selectedUser = useSelector((state) => state.selectedUserData);

  return selectedUser;
};

export default SelectedUser;
