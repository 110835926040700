// src/features/actions/postNewJob.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const postNewJob = createAsyncThunk('data/postNewJob', async (jobData) => {
  const response = await axios.post(`${backendUrl}/tyotPostNewJob`, jobData);
  return response.data;
});
