// PartsList.js
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllParts } from '../features/actions/getAllParts';
import { Table, Spinner, Alert } from 'react-bootstrap';
import AddNewPart from './AddNewPart';
import ResourcesOld from './ResourcesOld'; // Päivitetty komponentin nimi
import CreateResource from './CreateResource';

const PartsList = () => {
  const dispatch = useDispatch();
  const parts = useSelector((state) => state.data.partsData);
  const partsStatus = useSelector((state) => state.data.partsStatus);
  const [resourceCreated, setResourceCreated] = useState(false); // Lisätty tila resurssin luomisen onnistumisen seuraamiseksi

  useEffect(() => {
    dispatch(getAllParts());
  }, [dispatch]);

  const handlePartAdded = () => {
    dispatch(getAllParts()); // Päivitä osaluettelo uudelleen
  };

  // Funktio päivittää resurssin luomisen jälkeen ResourcesOld-komponentin
  const handleResourceCreated = () => {
    setResourceCreated(true); // Asetetaan tilaksi, että resurssi on luotu
  };

  return (
    <div>
      {partsStatus === 'loading' && <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>}
      {partsStatus === 'failed' && <Alert variant="danger">Error fetching parts data</Alert>}
      {partsStatus === 'succeeded' && (
        <div>
          <h2>Parts List</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Part ID</th>
                <th>Name</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {parts.map((part) => (
                <tr key={part.osaid}>
                  <td>{part.osaid}</td>
                  <td>{part.osanimi}</td>
                  <td>{part.sisaanostohinta}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <AddNewPart onPartAdded={handlePartAdded} />
          <CreateResource onResourceCreated={handleResourceCreated} /> {/* Lisätty prop-funktio */}
          <ResourcesOld /> {/* Päivitetty komponentin nimi */}
        </div>
      )}
    </div>
  );
};

export default PartsList;
