// src/features/actions/fetchWorkStatesData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchWorkStatesData = createAsyncThunk('data/fetchWorkStatesData', async () => {
  console.log('Fetching work states data...');
  const response = await axios.get(`${backendUrl}/tyotilat`);
  return response.data;
});
