import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { removeProjectData } from '../features/actions/removeProjectData';
import { fetchProjectsData } from '../features/actions/fetchProjectsData';

const RemoveProjectButton = ({ projectId }) => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false);

  const handleRemoveProject = async () => {
    setIsRemoving(true);
    try {
      await dispatch(removeProjectData(projectId));
      dispatch(fetchProjectsData());
    } catch (error) {
      console.error('Error removing project:', error);
    } finally {
      setIsRemoving(false);
    }
  };

  return (
      <Button variant="danger" size="sm" onClick={handleRemoveProject} disabled={isRemoving}>
        {isRemoving ? (
          <>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Removing...
          </>
        ) : (
          'Remove Project'
        )}
      </Button>
  );
};

export default RemoveProjectButton;
