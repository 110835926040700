// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../features/dataSlice';
import developerDataReducer from '../features/developerDataSlice';
import selectedUserDataReducer from '../features/selectedUserDataSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
    developerData: developerDataReducer,
    selectedUserData: selectedUserDataReducer,
  },
});

export default store;
