import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Button, Container, Row, Col, Modal, Table } from 'react-bootstrap';

const JobPartStates = ({ tyoid, selectedWorkPartId }) => {
  // Handler for opening modal
  const [showModal, setShowModal] = useState(false);

  // Redux hook to get work parts data from the state
  const workPartsData = useSelector((state) => state.data.workPartsData);

  // Filtering the work parts based on tyoid and finding the selected work part
  const filteredWorkParts = workPartsData.filter((workPart) => workPart.tyoid === tyoid);
  const selectedWorkPart = filteredWorkParts.find((workPart) => workPart.tyonosaid === selectedWorkPartId);

  // If no selected work part, return null
  if (!selectedWorkPart) {
    return null;
  }

  // Sorting work states based on the timestamp
  const sortedWorkStates = [...selectedWorkPart.tyonosanTyotilat].sort(
    (a, b) => new Date(b.muutosaika).getTime() - new Date(a.muutosaika).getTime()
  );

  // Handler for opening modal
  const handleModalOpen = () => {
    setShowModal(true);
  };

  // Handler for closing modal
  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div>
            {/*console.log("sortedWorkStates: ", sortedWorkStates[0])*/}
            <Badge bg={sortedWorkStates.length > 0 && sortedWorkStates[0].tyotilanluokka} className="ms-2">
              {sortedWorkStates.length > 0 && sortedWorkStates[0].tyotilannimi}
            </Badge>
          </div>

          {/* Button for opening modal */}
          <Button variant="link" onClick={handleModalOpen}>
            Show Old Work States
          </Button>

          {/* Modal for displaying old work states */}
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Old Work States</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Prioriteetti</th>
                    <th>Muuttaja</th>
                    <th>Muutosaika</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedWorkStates.map((status, index) => (
                    <tr key={index}>
                      <td>
                        <Badge bg={status.tyotilanluokka}>{status.tyotilannimi}</Badge>
                      </td>
                      <td>{status.tyotilanprioriteetti}</td>
                      <td>{status.muuttajannimi}</td>
                      <td>{new Date(status.muutosaika).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default JobPartStates;
