import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkPartsData } from '../features/actions/fetchWorkPartsData';
import { Container, Spinner, Alert, Tab, Nav, Row, Col } from 'react-bootstrap';
import JobPartStates from './JobPartStates';
import ChangeOrderButtonGroup from './ChangeOrderButtonGroup';
import LatestWorkState from './LatestWorkState';
import ResourcesView from './ResourcesView';

const JobParts = ({ tyoid, noDataFetching }) => {
  const dispatch = useDispatch();
  const { workPartsData, workPartsStatus, error } = useSelector((state) => state.data);
  const showDeveloperData = useSelector((state) => state.developerData.showDeveloperData);

  useEffect(() => {
    if (!noDataFetching) {
      dispatch(fetchWorkPartsData());
    }
  }, [dispatch, noDataFetching]);

  const [selectedResource, setSelectedResource] = useState(null);

  const handleSelectResource = (resource) => {
    setSelectedResource(resource);
  };

  const memoizedWorkPartsData = useMemo(() => {
    return tyoid ? workPartsData.filter(workPart => tyoid === workPart.tyoid) : workPartsData;
  }, [workPartsData, tyoid]);

  const renderWorkPartDetails = (workPart) => {
    if (!workPart.tyonosaresurssit || workPart.tyonosaresurssit.length === 0) {
      return (
        <>
          <h6>Lisää työnosaan resurssit:</h6>
          <p>Kuvaus: {workPart.kuvaus}</p>
          <p>Työ ID: {workPart.tyoid}</p>
          <p>Tarjoushinta: {workPart.tarjoushinta} €</p>
          <ResourcesView onSelectResource={handleSelectResource} tyonosaid={workPart.tyonosaid} />
          {selectedResource && <p>Selected resource: {selectedResource.nimi}</p>}
        </>
      );
    }

    return (
      <div key={workPart.tyonosaid}>
        <Row className="align-items-center">
          <Col xs={1}><ChangeOrderButtonGroup /></Col>
          <Col xs={7}><h5>{workPart.nimi}</h5></Col>
          <Col xs={4} className="text-end">
            <JobPartStates tyoid={workPart.tyoid} selectedWorkPartId={workPart.tyonosaid} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p>Kuvaus: {workPart.kuvaus}</p>
            <p>Työ ID: {workPart.tyoid}</p>
            <p>Tarjoushinta: {workPart.tarjoushinta} €</p>
            {workPart.tyonosaresurssit.map((resurssi, index) => (
              <div key={index}>
                <p><strong>{resurssi.resurssinnimi} koostuu näistä osista:</strong></p>
                <ul>
                  {resurssi.osat.map((osa, osaIndex) => (
                    <li key={osa.osaid}>
                      {osa.osanimi}
                      <ul>
                        <li>Osaid: {osa.osaid}</li>
                        <li>Sisaanostohinta: {osa.sisaanostohinta} €</li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            {showDeveloperData && (
              <pre>{JSON.stringify(workPart, null, 2)}</pre>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Container>
      {workPartsStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {workPartsStatus === 'failed' && <Alert variant="danger">Error: {error}</Alert>}
      {workPartsStatus === 'succeeded' && (
        <Tab.Container id="work-parts-tabs" defaultActiveKey="first">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {memoizedWorkPartsData.map((workPart) => (
                  <Nav.Item key={workPart.tyonosaid}>
                    <Nav.Link eventKey={workPart.tyonosaid}>
                      <Row>
                        <Col><LatestWorkState workPart={workPart} /></Col>
                        <Col><span>{workPart.nimi}</span></Col>
                      </Row>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {memoizedWorkPartsData.map((workPart) => (
                  <Tab.Pane key={workPart.tyonosaid} eventKey={workPart.tyonosaid}>
                    {renderWorkPartDetails(workPart)}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </Container>
  );
};

export default React.memo(JobParts);
