import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postNewPart } from '../features/actions/postNewPart';
import { Form, Button } from 'react-bootstrap';

const AddNewPart = ({ onPartAdded }) => {
  const dispatch = useDispatch();
  const [partData, setPartData] = useState({
    osanimi: '',
    sisaanostohinta: ''
  });
  const [addingPart, setAddingPart] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPartData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddingPart(true);
    try {
      await dispatch(postNewPart(partData));
      onPartAdded(); // Päivitä osaluettelo vanhemmalle komponentille
      setPartData({
        osanimi: '',
        sisaanostohinta: ''
      });
    } catch (error) {
      console.error('Error adding new part:', error);
    } finally {
      setAddingPart(false);
    }
  };

  return (
    <div>
      <h2>Add New Part</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="osanimi">
          <Form.Label>Name</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter part name" 
            name="osanimi" 
            value={partData.osanimi} 
            onChange={handleChange} 
            required 
          />
        </Form.Group>
        <Form.Group controlId="sisaanostohinta">
          <Form.Label>Price</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter price" 
            name="sisaanostohinta" 
            value={partData.sisaanostohinta} 
            onChange={handleChange} 
            required 
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={addingPart}>
          {addingPart ? 'Adding...' : 'Add Part'}
        </Button>
      </Form>
    </div>
  );
};

export default AddNewPart;
