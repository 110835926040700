import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTableNames } from '../features/actions/fetchTableNames';


import { Container, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WelcomeUser from './WelcomeUser';
import TaskList from './TaskList';



const Home = () => {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector((state) => state.data);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    dispatch(fetchTableNames());
  }, [dispatch]);

  return (
    <Container>
      <h2>Home Page</h2>
      <WelcomeUser />
        <Row>
          <Col xs={12} md={8}>
            <h3>TODO</h3>
            <TaskList />
            
            
          </Col>
          <Col xs={12} md={4}>
            <h3>Tables from Database:</h3>
            {status === 'loading' && <Spinner animation="border" variant="primary" />}
            {status === 'failed' && <Alert variant="danger">Error: {error}</Alert>}
            {status === 'succeeded' && (
              <ul>
                {data.map((item, index) => (
                  <li key={index}>
                    {/* Link each item to its corresponding route */}
                    <Link to={`${backendUrl}/${item}`} target="_blank" rel="noopener noreferrer">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </Col>
        </Row>
    </Container>
  );
};

export default Home;
