// src/features/selectedUserDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userLoggedIn: false,
  userData: [
    {
      "kayttajaid": "",
      "kayttajanimi": "",
      "salasana": "",
      "nimi": "",
      "osoite": "",
      "puhelinnumero": "",
      "sahkopostiosoite": "",
      "kayttajarooli": ""
  }
  ],
};

const selectedUserDataSlice = createSlice({
  name: 'selectedUserData',
  initialState,
  reducers: {
    setUserLoggedIn: (state, action) => {
        state.userLoggedIn = action.payload;
        return state; // Varmista tämä palautus
      },
      
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { setUserLoggedIn, setUserData } = selectedUserDataSlice.actions;

export default selectedUserDataSlice.reducer;
