// src/features/actions/postNewJobPart.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const postNewJobPart = createAsyncThunk('data/postNewJobPart', async (jobData) => {
  const response = await axios.post(`${backendUrl}/postNewJobPart`, jobData);
  return response.data;
});
