import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectsData } from '../features/actions/fetchProjectsData';
import { fetchWorkStatesData } from '../features/actions/fetchWorkStatesData';
import { fetchJobsData } from '../features/actions/fetchJobsData';
import { fetchWorkPartsData } from '../features/actions/fetchWorkPartsData';
import { Container, Spinner, Alert, Row, Col, Card, Accordion, Button, Form, ButtonGroup } from 'react-bootstrap';
import { containerStyle } from '../styles/ContainerStyle';
import RemoveJobButton from './RemoveJobButton';
import AddNewJobPartButton from './AddNewJobPartButton';
import BiArrowReturnRight from './Icons/BiArrowReturnRight';
import FirstWorkState from './FirstWorkState'; // Lisätty import

// Ladataan JobParts komponentti laiskasti
const JobParts = lazy(() => import('./JobParts'));

const Jobs = ({ projectId, noDataFetching }) => {
  const dispatch = useDispatch();
  const { jobsData, jobsStatus, error } = useSelector((state) => state.data);
  const showDeveloperData = useSelector((state) => state.developerData.showDeveloperData);
  const projectsData = useSelector((state) => state.data.projectsData);
  const selectedUser = useSelector((state) => state.selectedUserData);
  
  
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [jobAdded, setJobAdded] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId || '');

  useEffect(() => {
    if (!noDataFetching && !jobAdded) {
      dispatch(fetchJobsData());
      dispatch(fetchWorkPartsData());
      dispatch(fetchWorkStatesData());
      dispatch(fetchProjectsData());
    }
  }, [dispatch, noDataFetching, jobAdded]);

  const filteredJobsData = useMemo(() => {
    return selectedProjectId ? jobsData.filter(job => job.projektiid === selectedProjectId) : jobsData;
  }, [jobsData, selectedProjectId]);

  const handleTabSelect = (jobId) => {
    setSelectedJobId(jobId);
  };

  // Tähän muuttujaan tallennetaan ensimmäinen työtila
  const firstWorkState = FirstWorkState();

  const handleJobAdded = () => {
    setJobAdded(true);
    dispatch(fetchJobsData());
  };

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  return (
    <Container>
      {!noDataFetching && (
        <Form.Group controlId="selectProject">
          <Form.Label>Select Project</Form.Label>
          <Form.Control as="select" value={selectedProjectId} onChange={handleProjectChange}>
            <option value="">All Projects</option>
            {projectsData.map((project) => (
              <option key={project.projektiid} value={project.projektiid}>
                {project.projektinimi}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}
      
      {jobsStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {jobsStatus === 'failed' && <Alert variant="danger">Error: {error}</Alert>}
      {jobsStatus === 'succeeded' && (
        <Accordion defaultActiveKey={null}>
          {filteredJobsData.map((job) => (
            <Accordion.Item key={job.tyoid} eventKey={job.tyoid}>
              <Accordion.Header>
                <Row className="align-items-center w-100">
                  <Col xs={9}>{job.nimi || ''}</Col>
                  <Col xs={3} className="text-end">

                    <ButtonGroup>
                      <AddNewJobPartButton tyoid={job.tyoid} kayttajaid={selectedUser.userData.kayttajaid} tyotilaid={firstWorkState.tyotilaid}/>
                      <RemoveJobButton jobId={job.tyoid} />
                    </ButtonGroup>

                  </Col>
                </Row>            
              </Accordion.Header>

              <Accordion.Body>
                <Row>
                  <AddNewJobPartButton tyoid={job.tyoid} kayttajaid={selectedUser.userData.kayttajaid} tyotilaid={firstWorkState.tyotilaid}/>
                </Row>
                <Row>
                  <Col className="col-auto">
                    <BiArrowReturnRight />                     
                  </Col>                  
                  <Col>
                    {showDeveloperData && (
                      <Card>
                        <pre>{JSON.stringify(job, null, 2)}</pre>
                      </Card>
                    )}

                    {/* Ladataan JobParts-komponentti laiskasti */}
                    <Suspense fallback={<Spinner animation="border" variant="primary" />}>
                      <JobParts tyoid={job.tyoid} noDataFetching={false} />
                    </Suspense>

                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </Container>
  );
};

export default React.memo(Jobs);
