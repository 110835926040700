import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { fetchUsersData } from '../features/actions/fetchUsersData';

import { setUserData, setUserLoggedIn } from '../features/selectedUserDataSlice';

const SelectUserForTesting = () => {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.data.usersData);
  const usersStatus = useSelector((state) => state.data.usersStatus);
  const [selectedUser, setSelectedUser] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (usersStatus !== 'succeeded') {
      // Fetch users data if not already loaded or if loading failed
      dispatch(fetchUsersData());
    }
  }, [usersStatus, dispatch]);

  const handleUserSelect = (userId) => {
    setSelectedUser(userId);
    console.log("SelectedUserForTesting: ", userId);
  };

  const handleLogin = () => {
    // Your login logic here
    setIsLoading(true);

    // Simulating a delay for login, replace with actual login logic
    setTimeout(() => {
      // Find the selected user's data
      const selectedUserData = usersData.find(user => user.kayttajaid === selectedUser);

      // Dispatch actions to update selectedUserDataSlice
      dispatch(setUserData(selectedUserData));
      dispatch(setUserLoggedIn(true));

      setIsLoading(false);
      setIsLoggedIn(true);
    }, 1000); // Simulating a 1-second delay
  };

  const handleLogout = () => {
    dispatch(setUserLoggedIn(false));
    setIsLoggedIn(false);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <Form>
            <Form.Group controlId="selectUser">
              <Row>
                <Col>
                  <Form.Label>Select User for Testing:</Form.Label>
                </Col>
                <Col>
                  <Form.Control as="select" onChange={(e) => handleUserSelect(e.target.value)}>
                    <option value="">Select a user</option>
                    {usersStatus === 'loading' ? (
                      <option disabled>Loading users...</option>
                    ) : (
                      usersData.map((user) => (
                        <option key={user.kayttajaid} value={user.kayttajaid}>
                          {user.kayttajanimi}
                        </option>
                      ))
                    )}
                  </Form.Control>
                </Col>
                <Col>
                  {isLoggedIn ? (
                    <Button variant="primary" onClick={handleLogout}>
                      Log Out
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={handleLogin} disabled={!selectedUser || isLoading}>
                      {isLoading ? 'Logging in...' : 'Log In'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SelectUserForTesting;
