import { createSlice } from '@reduxjs/toolkit';
import { fetchTableNames } from './actions/fetchTableNames';
import { fetchWorkStatesData } from './actions/fetchWorkStatesData';
import { fetchUsersData } from './actions/fetchUsersData';
import { fetchResourcesData } from './actions/fetchResourcesData';
import { fetchResourcesDataById } from './actions/fetchResourcesDataById'; // Lisätty import
import { fetchWorkPartsData } from './actions/fetchWorkPartsData';
import { postProjectData } from './actions/postProjectData';
import { fetchProjectsData } from './actions/fetchProjectsData';
import { postNewJob } from './actions/postNewJob';
import { fetchJobsData } from './actions/fetchJobsData';
import { fetchCustomersData } from './actions/fetchCustomersData';
import { removeJobData } from './actions/removeJobData';
import { removeProjectData } from './actions/removeProjectData';
import { fetchProjectProgress } from './actions/fetchProjectProgress';
import { postNewJobPart } from './actions/postNewJobPart';
import { postResourcesToJobPart } from './actions/postResourcesToJobPart';
import { postNewPart } from './actions/postNewPart';
import { getAllParts } from './actions/getAllParts';
import { postNewResource } from './actions/postNewResource';
import { putUpdateResourceData } from './actions/putUpdateResourceData';
import { putUpdatePart } from './actions/putUpdatePart';
import { postAddPartToResource } from './actions/postAddPartToResource';

const initialState = {
  data: [],
  jobsData: [],
  workStatesData: [],
  customersData: [],
  usersData: [],
  resourcesData: [],
  projectsData: [],
  workPartsData: [],
  partsData: [],
  status: 'idle',
  jobsStatus: 'idle',
  workStatesStatus: 'idle',
  customersStatus: 'idle',
  usersStatus: 'idle',
  resourcesStatus: 'idle',
  projectsStatus: 'idle',
  workPartsStatus: 'idle',
  partsStatus: 'idle',
  error: null,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchTableNames
    builder
      .addCase(fetchTableNames.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTableNames.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTableNames.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // fetchJobsData
    builder
      .addCase(fetchJobsData.pending, (state) => {
        state.jobsStatus = 'loading';
      })
      .addCase(fetchJobsData.fulfilled, (state, action) => {
        state.jobsStatus = 'succeeded';
        state.jobsData = action.payload;
      })
      .addCase(fetchJobsData.rejected, (state, action) => {
        state.jobsStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchWorkStatesData
    builder
      .addCase(fetchWorkStatesData.pending, (state) => {
        state.workStatesStatus = 'loading';
      })
      .addCase(fetchWorkStatesData.fulfilled, (state, action) => {
        state.workStatesStatus = 'succeeded';
        state.workStatesData = action.payload;
      })
      .addCase(fetchWorkStatesData.rejected, (state, action) => {
        state.workStatesStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchCustomersData
    builder
      .addCase(fetchCustomersData.pending, (state) => {
        state.customersStatus = 'loading';
      })
      .addCase(fetchCustomersData.fulfilled, (state, action) => {
        state.customersStatus = 'succeeded';
        state.customersData = action.payload;
      })
      .addCase(fetchCustomersData.rejected, (state, action) => {
        state.customersStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchUsersData
    builder
      .addCase(fetchUsersData.pending, (state) => {
        state.usersStatus = 'loading';
      })
      .addCase(fetchUsersData.fulfilled, (state, action) => {
        state.usersStatus = 'succeeded';
        state.usersData = action.payload;
      })
      .addCase(fetchUsersData.rejected, (state, action) => {
        state.usersStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchResourcesData
    builder
      .addCase(fetchResourcesData.pending, (state) => {
        state.resourcesStatus = 'loading';
      })
      .addCase(fetchResourcesData.fulfilled, (state, action) => {
        state.resourcesStatus = 'succeeded';
        state.resourcesData = action.payload;
      })
      .addCase(fetchResourcesData.rejected, (state, action) => {
        state.resourcesStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchProjectsData
    builder
      .addCase(fetchProjectsData.pending, (state) => {
        state.projectsStatus = 'loading';
      })
      .addCase(fetchProjectsData.fulfilled, (state, action) => {
        state.projectsStatus = 'succeeded';
        state.projectsData = action.payload;
      })
      .addCase(fetchProjectsData.rejected, (state, action) => {
        state.projectsStatus = 'failed';
        state.error = action.error.message;
      });

    // fetchWorkPartsData
    builder
      .addCase(fetchWorkPartsData.pending, (state) => {
        state.workPartsStatus = 'loading';
      })
      .addCase(fetchWorkPartsData.fulfilled, (state, action) => {
        state.workPartsStatus = 'succeeded';
        state.workPartsData = action.payload;
      })
      .addCase(fetchWorkPartsData.rejected, (state, action) => {
        state.workPartsStatus = 'failed';
        state.error = action.error.message;
      });

    // postProjectData
    builder
      .addCase(postProjectData.pending, (state) => {
        state.projectsStatus = 'loading';
      })
      .addCase(postProjectData.fulfilled, (state, action) => {
        state.projectsStatus = 'succeeded';
        state.projectsData = action.payload;
      })
      .addCase(postProjectData.rejected, (state, action) => {
        state.projectsStatus = 'failed';
        state.error = action.error.message;
      });

    // postNewJob
    builder
      .addCase(postNewJob.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNewJob.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobsData.push(action.payload);
      })
      .addCase(postNewJob.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // removeProjectData
    builder
      .addCase(removeProjectData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeProjectData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobsData.push(action.payload);
      })
      .addCase(removeProjectData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // removeJobData
    builder
      .addCase(removeJobData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeJobData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobsData.push(action.payload);
      })
      .addCase(removeJobData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // fetchProjectProgress
    builder
      .addCase(fetchProjectProgress.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProjectProgress.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.projectsData = action.payload;
      })
      .addCase(fetchProjectProgress.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // postNewJobPart
    builder
      .addCase(postNewJobPart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNewJobPart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobsData.push(action.payload);
      })
      .addCase(postNewJobPart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // postResourcesToJobPart
    builder
      .addCase(postResourcesToJobPart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postResourcesToJobPart.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(postResourcesToJobPart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // postNewPart
    builder
      .addCase(postNewPart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNewPart.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(postNewPart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // getAllParts
    builder
      .addCase(getAllParts.pending, (state) => {
        state.partsStatus = 'loading';
      })
      .addCase(getAllParts.fulfilled, (state, action) => {
        state.partsStatus = 'succeeded';
        state.partsData = action.payload;
      })
      .addCase(getAllParts.rejected, (state, action) => {
        state.partsStatus = 'failed';
        state.error = action.error.message;
      });

    // postNewResource
    builder
      .addCase(postNewResource.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNewResource.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(postNewResource.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // putUpdateResourceData
    builder
      .addCase(putUpdateResourceData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putUpdateResourceData.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(putUpdateResourceData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // putUpdatePart
    builder
      .addCase(putUpdatePart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putUpdatePart.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(putUpdatePart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });

    // postAddPartToResource
    builder
      .addCase(postAddPartToResource.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postAddPartToResource.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(postAddPartToResource.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
      
    // fetchResourcesDataById
    builder
      .addCase(fetchResourcesDataById.pending, (state) => {
        state.resourcesStatus = 'loading';
      })
      .addCase(fetchResourcesDataById.fulfilled, (state, action) => {
        state.resourcesStatus = 'succeeded';
        state.resourcesData = action.payload;
      })
      .addCase(fetchResourcesDataById.rejected, (state, action) => {
        state.resourcesStatus = 'failed';
        state.error = action.error.message;
      });      
  },
});

export default dataSlice.reducer;
