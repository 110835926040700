import React, { useState } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import SearchFromCustomers from './SearchFromCustomers';

const AddProject = ({ handleAddProjectSubmit }) => {
  const [newProjectName, setNewProjectName] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleAddProject = () => {
    if (newProjectName && selectedCustomer) {
      const projectData = {
        projektinimi: newProjectName,
        asiakasid: selectedCustomer.asiakasid,
      };
      handleAddProjectSubmit(projectData);
      setSelectedCustomer(null);
      setNewProjectName('');
    } else {
      console.error('Project name and customer must be selected');
    }
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
  };

  return (
    <Container>
      <Button variant="primary" onClick={handleAddProject} className="mb-3">
        Add Project
      </Button>
      <SearchFromCustomers onSelectCustomer={handleSelectCustomer} />
      <Form>
        <Form.Group controlId="formProjectName">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter project name"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </Form.Group>
        {selectedCustomer && (
          <Form.Group controlId="formCustomerName">
            <Form.Label>Customer Name</Form.Label>
            <Form.Control type="text" value={selectedCustomer.nimi} readOnly />
          </Form.Group>
        )}
      </Form>
    </Container>
  );
};

export default AddProject;
