import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the base URL for your API
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// Define the thunk for posting resources to a job part
export const postResourcesToJobPart = createAsyncThunk(
  'data/postResourcesToJobPart',
  async (postData, thunkAPI) => {
    try {
      // Make a POST request to the API endpoint for posting resources to a job part
      const response = await axios.post(`${backendUrl}/postResourcesToJobPart`, postData);

      // Return the response data
      return response.data;
    } catch (error) {
      // If an error occurs, throw the error to be handled by Redux Toolkit
      throw error;
    }
  }
);
