import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import { postNewResource } from '../features/actions/postNewResource';

const CreateResource = ({ onResourceCreated }) => { // Lisätty prop "onResourceCreated"
  const dispatch = useDispatch();
  const [resourceData, setResourceData] = useState({
    nimi: '',
    ulosmyyntihinta: 0,
    kateprosentti: 0,
    yksikko: '',
    seuraaaikaa: false,
    ominaisuudet: '',
    osat: [],
  });
  const [selectedParts, setSelectedParts] = useState([]);
  const [totalKateprosentti, setTotalKateprosentti] = useState(0);
  const [resourceCreated, setResourceCreated] = useState(false); // Lisätty tila resurssin luomisen onnistumisen seuraamiseksi

  // Haetaan osat Redux-tilasta
  const partsData = useSelector((state) => state.data.partsData);

  // Tässä voit käyttää omaa kateprosentin laskemisen logiikkaa
  const calculateKateprosentti = (selectedParts, price) => {
    // Lasketaan valittujen osien sisaanostohinnan summa
    const totalKateprosentti = selectedParts.reduce((sum, part) => {
      // Muunnetaan sisaanostohinta numeroksi ja lisätään summaan
      return sum + parseFloat(part.sisaanostohinta);
    }, 0);

    // Lasketaan katetuotto
    const katetuotto = price - totalKateprosentti;

    // Lasketaan kateprosentti
    const kateprosentti = (katetuotto / price) * 100;

    // Palautetaan kateprosentti
    return kateprosentti;
  };

  // Tässä käsitellään valitut osat ja lasketaan niiden perusteella kateprosentti
  useEffect(() => {
    const kateprosentti = calculateKateprosentti(selectedParts, resourceData.ulosmyyntihinta);
    setTotalKateprosentti(kateprosentti);
    setResourceData((prevData) => ({
      ...prevData,
      kateprosentti: kateprosentti,
      osat: selectedParts.map(part => ({ osaid: part.value })),
    }));
  }, [selectedParts, resourceData.ulosmyyntihinta]);

  // Lähetetään uusi resurssidata palvelimelle
  const handleSubmit = async (event) => { // Lisätty async asynkronisen toiminnon suorittamiseksi
    event.preventDefault();
    await dispatch(postNewResource(resourceData)); // Käytetään awaitia odottamaan resurssin luomisen valmistumista
    setResourceCreated(true); // Asetetaan tilaksi, että resurssi on luotu
    onResourceCreated(); // Kutsutaan prop-funktiota resurssin luomisen jälkeen
  };

  // Muunnetaan osaobjektit muotoon, joka sopii react-selectin käyttöön
  const options = partsData.map(part => ({ value: part.osaid, label: part.osanimi, sisaanostohinta: part.sisaanostohinta }));

  // Jos resurssi on luotu onnistuneesti, näytetään onnistumisviesti
  if (resourceCreated) {
    return (
      <div>
        <h2>Resource Created Successfully!</h2>
      </div>
    );
  }

  // Muuten renderöidään resurssin luontilomake
  return (
    <div>
      <h2>Create Resource</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formResourceName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter resource name"
            value={resourceData.nimi}
            onChange={(e) => setResourceData({ ...resourceData, nimi: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourcePrice">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter price"
            value={resourceData.ulosmyyntihinta}
            onChange={(e) => setResourceData({ ...resourceData, ulosmyyntihinta: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceUnit">
          <Form.Label>Unit</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter unit"
            value={resourceData.yksikko}
            onChange={(e) => setResourceData({ ...resourceData, yksikko: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceFollowTime">
          <Form.Check
            type="checkbox"
            label="Follow Time"
            checked={resourceData.seuraaaikaa}
            onChange={(e) => setResourceData({ ...resourceData, seuraaaikaa: e.target.checked })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceFeatures">
          <Form.Label>Features</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter features"
            value={resourceData.ominaisuudet}
            onChange={(e) => setResourceData({ ...resourceData, ominaisuudet: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceParts">
          <Form.Label>Parts</Form.Label>
          <Select
            options={options}
            isMulti
            onChange={setSelectedParts}
          />
        </Form.Group>

        <Form.Group controlId="formResourceKate">
          <Form.Label>Kateprosentti</Form.Label>
          <Form.Control
            type="text"
            placeholder="Kateprosentti"
            value={`${totalKateprosentti} %`}
            readOnly
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Create Resource
        </Button>
      </Form>
    </div>
  );
};

export default CreateResource;
