import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const tasks = [
  { date: new Date(2024, 3, 1), title: 'Task 1' },
  { date: new Date(2024, 3, 5), title: 'Task 2' },
];

const MyCalendar = ({ show, handleClose }) => {
  const [value, onChange] = useState(new Date());

  // Render function to customize calendar tile content
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const taskForDate = tasks.find(task => task.date.toDateString() === date.toDateString());
      return taskForDate ? <p>{taskForDate.title}</p> : null;
    }
    return null;
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Event Calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h1>Calendar</h1>
          <Calendar
            onChange={onChange}
            value={value}
            tileContent={tileContent}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MyCalendar;
