import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const FirstWorkState = () => {

    const workStatesData = useSelector((state) => state.data.workStatesData);

    const smallestPriorityItem = workStatesData.reduce((minItem, currentItem) => {
        if (currentItem.prioriteetti < minItem.prioriteetti) {
            return currentItem;
        }
        return minItem;
    }, workStatesData[0]);


    //console.log(smallestPriorityItem);

    return smallestPriorityItem // Palautetaan pelkkä tieto ilman muotoilua
};

export default FirstWorkState;
