import React, { useState, useEffect } from 'react';
import tasks from '../data/tasks.md';

const TaskList = () => {
  const [tasksContent, setTasksContent] = useState('');

  useEffect(() => {
    // Lataa tehtävät tiedostosta
    fetch(tasks)
      .then((response) => response.text())
      .then((data) => setTasksContent(data));
  }, []);

  const handleTaskToggle = (index) => {
    // Merkitse tehtävä suoritetuksi
    setTasksContent((prevTasks) =>
      prevTasks
        .split('\n')
        .map((task, i) => (i === index ? (task.includes('[ ]') ? task.replace('[ ]', '[x]') : task) : task))
        .join('\n')
    );
  };

  const renderTasks = (content) => {
    const lines = content.split('\n');
    const renderedTasks = [];

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.startsWith('#')) {
        // Otsikko
        renderedTasks.push(<h3 key={i}>{line.substring(2)}</h3>);
      } else if (line.trim() !== '') {
        // Tehtävä
        renderedTasks.push(
          <li key={i}>
            <input
              type="checkbox"
              checked={line.includes('[x]')}
              onChange={() => handleTaskToggle(i)}
            />
            <span dangerouslySetInnerHTML={{ __html: line }} />
          </li>
        );
      }
    }

    return renderedTasks;
  };

  return (
    <div>
      <h2>Development Tasks</h2>
      <ul>{tasksContent && renderTasks(tasksContent)}</ul>
    </div>
  );
};

export default TaskList;
