// src/features/developerDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showDeveloperData: false, // Oletusarvo
};

const developerDataSlice = createSlice({
  name: 'developerData',
  initialState,
  reducers: {
    toggleShowDeveloperData: (state) => {
      state.showDeveloperData = !state.showDeveloperData;
    },
  },
});

export const { toggleShowDeveloperData } = developerDataSlice.actions;

export default developerDataSlice.reducer;
