// src/features/actions/fetchTableNames.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchTableNames = createAsyncThunk('data/fetchTableNames', async () => {
    const response = await axios.get(`${backendUrl}/`);
    return response.data;
  });
  
