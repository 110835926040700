import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap'; // Lisätty Modal
import AddJob from './AddJob';

const AddJobButton = ({ onJobAdded }) => {
  const [showAddJobModal, setShowAddJobModal] = useState(false);

  const handleAddJob = () => {
    setShowAddJobModal(true);
  };

  const handleClose = () => {
    setShowAddJobModal(false);
  };

  return (
    <>
      <Button size="sm" variant="primary" onClick={handleAddJob}>
        Add Job
      </Button>
      <Modal show={showAddJobModal} onHide={handleClose}> {/* Muutettu Modal */}
        <Modal.Header closeButton>
          <Modal.Title>Add New Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddJob onJobAdded={onJobAdded} />
        </Modal.Body>
        {/* Modal.Footer tarvittaessa */}
      </Modal>
    </>
  );
};

export default AddJobButton;
