import React, { useEffect, useState } from 'react';

const TimeToDeadline = ({ projectDeadline }) => {
  const [deadline, setDeadline] = useState(new Date(projectDeadline));

  useEffect(() => {
    const interval = setInterval(() => {
      setDeadline(prevDeadline => {
        const newDeadline = new Date(prevDeadline);
        newDeadline.setTime(newDeadline.getTime() - 1000); // Vähentää sekunti
        return newDeadline;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const calculateTime = (time) => {
    // Muunna millisekunnit päiviksi, tunneiksi, minuuteiksi ja sekunneiksi
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} päivää ${hours} tuntia ${minutes} minuuttia`;
  };

  const timeRemaining = deadline.getTime() - new Date().getTime();

  return (
    <>
      <h6>Aikaa deadlineen:</h6>
      <p style={{ color: timeRemaining < 0 ? 'red' : 'black' }}>{calculateTime(timeRemaining)}</p>
    </>
  );
};

export default TimeToDeadline;
