// src/components/JobPartsGanttView.js

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Container, Row, Col, Card } from 'react-bootstrap';

const initialJobParts = [
  { id: '1', stage: 'Received', description: 'Job Part 1' },
  { id: '2', stage: 'In Progress', description: 'Job Part 2' },
  { id: '3', stage: 'Completed', description: 'Job Part 3' },
  { id: '4', stage: 'Received', description: 'Job Part 4' },
  { id: '5', stage: 'Completed', description: 'Job Part 5' },
];

const JobPartsGanttView = () => {
  const [jobParts, setJobParts] = useState(initialJobParts);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return; // Dropped outside the list
    }

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return; // Dropped at the same place
    }

    // Find the dragged item
    const itemCopy = {...jobParts.find((jobPart) => jobPart.id === result.draggableId)};

    // Update the stage based on where it was dropped
    itemCopy.stage = destination.droppableId;

    // Create a new array without the dragged item
    let newJobParts = jobParts.filter((jobPart) => jobPart.id !== itemCopy.id);

    // Insert the item into the correct position in the new stage
    newJobParts.splice(destination.index, 0, itemCopy);

    // Update the state
    setJobParts(newJobParts);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container className="mt-3">
        <Row>
          {['Received', 'In Progress', 'Completed'].map((stage, index) => (
            <Droppable droppableId={stage} key={index}>
              {(provided) => (
                <Col ref={provided.innerRef} {...provided.droppableProps}>
                  <h2>{stage}</h2>
                  {jobParts.filter(jobPart => jobPart.stage === stage).map((jobPart, index) => (
                    <Draggable key={jobPart.id} draggableId={jobPart.id} index={index}>
                      {(provided) => (
                        <Card
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mb-3"
                        >
                          <Card.Body>
                            <Card.Title>{jobPart.description}</Card.Title>
                            <Card.Text>
                              Stage: {jobPart.stage}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Col>
              )}
            </Droppable>
          ))}
        </Row>
      </Container>
    </DragDropContext>
  );
};

export default JobPartsGanttView;
