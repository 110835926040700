// src/features/actions/fetchJobsData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchJobsData = createAsyncThunk('data/fetchJobsData', async () => {
  const response = await axios.get(`${backendUrl}/tyotGetAll`);
  return response.data;
});
