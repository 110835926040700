// src/features/actions/fetchProjectProgresess.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchProjectProgress = createAsyncThunk('data/ProjectProgress', async (projectId) => {
  try {
    const response = await axios.get(`${backendUrl}/projektitGetProgress/${projectId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});
