import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Spinner, Alert, Accordion, Button, Col, Row, Card, ButtonGroup, Offcanvas } from 'react-bootstrap';
import Jobs from './Jobs';
import RemoveProjectButton from './RemoveProjectButton';
import { fetchProjectsData } from '../features/actions/fetchProjectsData';
import { fetchJobsData } from '../features/actions/fetchJobsData';
import { postProjectData } from '../features/actions/postProjectData';
import AddNewProjectButton from './AddNewProjectButton';
import ProjectProgressBar from './ProjectProgressBar';
import { containerStyle } from '../styles/ContainerStyle';
import TimeToDeadline from './TimeToDeadline';
import AddJobButton from './AddJobButton';
import BiArrowReturnRight from './Icons/BiArrowReturnRight';
import MyCalendar from './Calendar'; // Lisätty Calendar-komponentin import
import SortProjects from './SortProjects'; // Lisätty SortProjects-komponentin import
import { fetchWorkStatesData } from '../features/actions/fetchWorkStatesData';

const Projects = () => {
  const dispatch = useDispatch();
  const { projectsData, projectsStatus, error } = useSelector((state) => state.data);
  const showDeveloperData = useSelector((state) => state.developerData.showDeveloperData);

  useEffect(() => {
    dispatch(fetchProjectsData());
    dispatch(fetchJobsData());
    dispatch(fetchWorkStatesData());
  }, [dispatch]);

  const [showAddProjectAlert, setShowAddProjectAlert] = useState(false);
  const [showAddProjectForm, setShowAddProjectForm] = useState(false);
  const [showSortConditions, setShowSortConditions] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false); // Lisätty state kalenterin näyttämiseen

  const handleAddProjectSubmit = async (projectData) => {
    await dispatch(postProjectData(projectData));
    dispatch(fetchProjectsData());
    setShowAddProjectAlert(true);
    setShowAddProjectForm(false);
    setTimeout(() => {
      setShowAddProjectAlert(false);
    }, 2000);
  };

  const handleEditProject = (projectId, projectName) => {
    console.log("edit project")
  };

  return (
    <Container style={containerStyle}>
      {projectsStatus === 'loading' && (
        <>
          <Spinner animation="border" variant="primary" />
          <p>Loading project data...</p>
        </>
      )}
      {projectsStatus === 'failed' && (
        <Alert variant="danger">Data loading failed!</Alert>
      )}
      {projectsStatus === 'succeeded' && (
        <>
          {showAddProjectAlert && (
            <Alert variant="info" onClose={() => setShowAddProjectAlert(false)} dismissible>
              Add a new project alert
            </Alert>
          )}
          {/*<p>Loading complete!</p>*/}
        </>
      )}     
      
      <Row>
        <Col>
          <h2>Projects Page</h2>
        </Col>
        <Col>
          <ButtonGroup className="float-end">
            <AddNewProjectButton handleAddProjectSubmit={handleAddProjectSubmit} />          
            <Button size="sm" onClick={() => setShowCalendar(true)}>Show calendar view</Button> {/* Lisätty napin toiminnallisuus kalenterin näyttämiseen */}
            <Button size="sm" onClick={() => setShowSortConditions(true)}>Sort conditions</Button>
          </ButtonGroup>
        </Col>
      </Row>

      {/* Lisätty Calendar-komponentin renderöinti, jos showCalendar on tosi */}
      {showCalendar && <MyCalendar show={showCalendar} handleClose={() => setShowCalendar(false)} />}

      <Row>
        <Col>
          <Accordion defaultActiveKey={null}>
            {Array.isArray(projectsData) && projectsData.map((project, index) => (
              <Accordion.Item key={project.projektiid} eventKey={index.toString()}>
                <Accordion.Header>                
                  <Row>
                    <Col xs={12} md={2}>
                      <h6>{project.projektinimi}</h6>
                    </Col>
                    <Col xs={12} md={6}>
                      <ProjectProgressBar id={project.projektiid} progress={project.progress} />
                    </Col>
                    <Col xs={12} md={4}>
                      {/*console.log(project.deadline)*/}
                      <TimeToDeadline projectDeadline={project.deadline} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ButtonGroup>
                        <RemoveProjectButton projectId={project.projektiid} />
                        <Button size="sm" variant="primary" onClick={() => handleEditProject(project.projektiid, project.projektinimi)}>
                          Edit Project
                        </Button>
                        <AddJobButton projectId={project.projektiid} />
                      </ButtonGroup>
                    </Col>
                  </Row>

                </Accordion.Header>

                <Accordion.Body>
                  <Row>
                    <Col>
                      {showDeveloperData && (
                        <Card>
                          <pre>{JSON.stringify(project, null, 2)}</pre>
                        </Card>
                        )}                      
                    </Col>
                  </Row>
                  <Row>
                    {/* Nuoli oikealle svg */}
                    <Col className="col-auto">
                      <BiArrowReturnRight />                     
                    </Col>
                    <Col>
                      <Jobs projectId={project.projektiid} noDataFetching={true} />
                    </Col>
                  </Row> 

                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>

      {/* Offcanvas for sort conditions */}
      <SortProjects show={showSortConditions} handleClose={() => setShowSortConditions(false)} />

    </Container>
  );
};

export default Projects;