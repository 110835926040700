import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResourcesData } from '../features/actions/fetchResourcesData';
import { postResourcesToJobPart } from '../features/actions/postResourcesToJobPart'; // Lisätty postResourcesToJobPart action

import { Container, Spinner, Alert, Table, Row, Col, Collapse, Form } from 'react-bootstrap';

const ResourcesView = ({ onSelectResource, tyonosaid }) => {
  const dispatch = useDispatch();
  const resourcesData = useSelector((state) => state.data.resourcesData);
  const resourcesStatus = useSelector((state) => state.data.resourcesStatus);

  const [sortedResources, setSortedResources] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [expandedResource, setExpandedResource] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [filterByClass, setFilterByClass] = useState('');

  const uniqueClasses = useMemo(() => {
    const classes = resourcesData.map(resource => resource.luokka);
    return [...new Set(classes)];
  }, [resourcesData]);

  console.log('Resources.js: tyonosaid: ', tyonosaid);

  useEffect(() => {
    if (resourcesStatus === 'idle') {
      dispatch(fetchResourcesData());
    }
  }, [resourcesStatus, dispatch]);

  useEffect(() => {
    setSortedResources([...resourcesData]);
  }, [resourcesData]);

  const handleSort = (key) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);

    const sorted = [...sortedResources].sort((a, b) => {
      if (key === 'seuraaaikaa') {
        // Special handling for the seuraaaikaa column (boolean)
        return order === 'asc' ? a[key] - b[key] : b[key] - a[key];
      } else {
        // Default handling for other columns
        return order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
    });

    setSortedResources(sorted);
  };

  const handleRowClick = (resource) => {
    if (expandedResource === resource.resurssiid) {
      setExpandedResource(null);
    } else {
      setExpandedResource(resource.resurssiid);
    }
    setSelectedResource(resource);

    // Lähetetään post-pyyntö postResourcesToJobPart actionin kautta
    dispatch(postResourcesToJobPart({ tyonosaid, resurssiid: resource.resurssiid }));
    console.log('Resources.js: handleRowClick: ', tyonosaid, resource.resurssiid);
    onSelectResource(resource);
  };

  const handleClassFilterChange = (event) => {
    setFilterByClass(event.target.value);
  };

  const filteredResources = filterByClass
    ? sortedResources.filter(resource => resource.luokka === filterByClass)
    : sortedResources;

  return (
    <Container>
      <h2>Resources</h2>

      {selectedResource && (
        <Alert variant="success">
          Resource "{selectedResource.nimi}" selected! Tyonosaid: {tyonosaid}, Resurssiid: {selectedResource.resurssiid}
        </Alert>
      )}

      {resourcesStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {resourcesStatus === 'failed' && <Alert variant="danger">Error loading resources data</Alert>}
      {resourcesStatus === 'succeeded' && (
        <Row>
          <Col>
            <Form.Group controlId="classFilter">
              <Form.Label>Filter by Class:</Form.Label>
              <Form.Control
                as="select"
                value={filterByClass}
                onChange={handleClassFilterChange}
              >
                <option value="">All</option>
                {uniqueClasses.map((classItem, index) => (
                  <option key={index} value={classItem}>{classItem}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('nimi')}>Nimi</th>
                </tr>
              </thead>
              <tbody>
                {filteredResources.map((resource) => (
                  <React.Fragment key={resource.resurssiid}>
                    <tr onClick={() => handleRowClick(resource)}>
                      <td>{resource.nimi}</td>
                    </tr>
                    <tr>
                      <td colSpan="1">
                        <Collapse in={expandedResource === resource.resurssiid}>
                          <div>
                            <p>Resurssi ID: {resource.resurssiid}</p>
                            <p>Ulosmyyntihinta: {resource.ulosmyyntihinta} €</p>
                            <p>Kateprosentti: {resource.kateprosentti} %</p>
                            <p>Yksikkö: {resource.yksikko}</p>
                            <p>Seuraa aikaa: {resource.seuraaaikaa.toString()}</p>
                            <p>Ominaisuudet: {resource.ominaisuudet}</p>
                            <p>Luokka: {resource.luokka}</p>
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ResourcesView;
