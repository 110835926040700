import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchResourcesDataById = createAsyncThunk(
  'data/fetchResourcesDataById',
  async (resourceId) => {
    const response = await axios.get(`${backendUrl}/getResourcesById/${resourceId}`);
    return response.data;
  }
);
