// src/components/Customers.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Container, Row, ListGroup, Form, } from 'react-bootstrap';


import YtjSearch from './YtjSearch';
import { fetchCustomersData } from '../features/actions/fetchCustomersData';

const Customers = () => {
  const dispatch = useDispatch();
  const customersData = useSelector((state) => state.data.customersData);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(fetchCustomersData());
  }, [dispatch]);

  const filteredCustomers = customersData.filter((customer) =>
    customer.nimi.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastCustomer = activePage * itemsPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  const renderCustomerNames = () => {
    if (!currentCustomers || currentCustomers.length === 0) {
      return <p>No matching customers found.</p>;
    }

    return (
      <ListGroup horizontal>
        {currentCustomers.map((customer) => (
          <ListGroup.Item key={customer.asiakasid} action onClick={() => setSelectedCustomer(customer)}>
            {customer.nimi}
          </ListGroup.Item>
        ))}
      </ListGroup>
    );
  };

  const renderCustomerDetails = () => {
    if (!selectedCustomer) {
      return null;
    }

    return (
      <Card>
        <Card.Header>{selectedCustomer.nimi}</Card.Header>
        <Card.Body>
          <Card.Text>
            {Object.entries(selectedCustomer).map(([key, value]) => (
              <p key={key}>
                <strong>{key}:</strong> {value}
              </p>
            ))}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };


  return (
    <Container>
      <Row>
        <YtjSearch />
      </Row>

      <Row>
        <Form.Group controlId="searchTerm">
          <Form.Control
            type="text"
            placeholder="Search by customer name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>        
      </Row>

      <Row>
        {renderCustomerNames()}
      </Row>

      <Row>
        {renderCustomerDetails()}
      </Row>

    </Container>
  );
};

export default Customers;
