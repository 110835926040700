import React, { useState } from 'react';
import { Button, Form, Alert, Spinner } from 'react-bootstrap'; // Lisätty Spinner
import { useSelector, useDispatch } from 'react-redux';
import { postNewJob } from '../features/actions/postNewJob';



const AddJob = ({ handleClose, handleAddJob, onJobAdded }) => {
  const dispatch = useDispatch();

  // Projektit Redux-tilasta
  const projects = useSelector((state) => state.data.projectsData);

  // Työtilat Redux-tilasta
  const workStates = useSelector((state) => state.data.workStatesData);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [selectedWorkStateId, setSelectedWorkStateId] = useState('');
  const [jobName, setJobName] = useState('');
  const [jobPrice, setJobPrice] = useState(''); // Lisätty tarjoushinnan tila
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
/*
  useEffect(() => {
    // Haetaan projektit
    dispatch(fetchProjectsData());
  }, [dispatch]);
*/
/*
  useEffect(() => {
    // Haetaan työtilat, jos niitä ei ole vielä haettu
    if (workStates.length === 0) {
      dispatch(fetchWorkStatesData());
    }
  }, [dispatch, workStates]);
*/

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Asetetaan loading-tila todeksi
    setLoading(true);

    const selectedProject = projects.find(project => project.projektinimi === selectedProjectName);
    if (!selectedProject) {
      console.error('Error: Project not found');
      return;
    }

    const newJob = {
      nimi: jobName,
      tarjoushinta: jobPrice, // Lisätty tarjoushinta
      projektiid: selectedProject.projektiid,
    };

    try {
      await dispatch(postNewJob(newJob));
      
      setJobName('');
      setJobPrice(''); // Tyhjennetään tarjoushinta lomakkeesta
      setSelectedProjectName('');
      setSelectedWorkStateId('');
      setShowAlert(true);

      // Kutsu onJobAdded-funktiota, kun työ on lisätty
      onJobAdded();
    } catch (error) {
      console.error('Error adding job:', error);
    } finally {
      // Asetetaan loading-tila epätodeksi lopuksi
      setLoading(false);
    }
  };

  const handleSelect = (projektinimi) => {
    setSelectedProjectName(projektinimi);
  };

  const handleWorkStateSelect = (workStateId) => {
    setSelectedWorkStateId(workStateId);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formJobName">
          <Form.Label>Job Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter job name"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formJobPrice"> {/* Lisätty tarjoushinnan lomakekenttä */}
          <Form.Label>Job Price</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter job price"
            value={jobPrice}
            onChange={(e) => setJobPrice(e.target.value)}
          />
        </Form.Group>

        <select onChange={(e) => handleSelect(e.target.value)}>
          <option value="">Select Project</option>
          {projects.map((project) => (
            <option key={project.projektiid} value={project.projektinimi}>
              {project.projektinimi}
            </option>
          ))}
        </select>

        {/*
        <select value={selectedWorkStateId} onChange={(e) => handleWorkStateSelect(e.target.value)}>
          <option value="">Select Work State</option>
          {workStates.map((workState) => (
            <option key={workState.tyotilaid} value={workState.tyotilaid}>
              {workState.nimi}
            </option>
          ))}
        </select>
          */}

        <Button variant="primary" type="submit" disabled={loading}>
          {loading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Add Job'
          )}
        </Button>
      </Form>
      {showAlert && (
        <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
          Job added successfully: {jobName}
        </Alert>
      )}
    </>
  );
};

export default AddJob;
