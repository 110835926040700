// src/components/WelcomeUser.js
import React from 'react';
import SelectedUser from './SelectedUser';

const WelcomeUser = () => {
  // Hae valitun käyttäjän tiedot SelectedUser-komponentista
  const userData = SelectedUser();

  return (
    <div style={{ marginTop: '10px' }}>
      {userData ? (
        <h3>Welcome, {userData.kayttajanimi}!</h3>
      ) : (
        <h3>Please log in to access more features.</h3>
      )}
    </div>
  );
};

export default WelcomeUser;
