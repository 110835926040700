import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const putUpdatePart = createAsyncThunk(
  'parts/putUpdatePart',
  async (updatedPartData, thunkAPI) => {
    try {
      const { osaid, ...data } = updatedPartData;
      const response = await axios.put(`${backendUrl}/putUpdatePart/${osaid}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
