// src/features/actions/postNewPart.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const postNewPart = createAsyncThunk('data/postNewPart', async (projectData) => {
  const response = await axios.post(`${backendUrl}/postNewPart`, projectData);
  return response.data;
});
