// src/components/MainRouter.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavigationBar from './NavigationBar';
import Home from './Home';
import Customers from './Customers';
import Projects from './Projects';
import Jobs from './Jobs';
import Users from './Users';
import ResourcesView from './ResourcesView';
import JobParts from './JobParts';
import MyComponent from './MyComponent';
import Login from './Login';
import PartsList from './PartsList';
import Resources from './Resources/index';
import JobPartsGanttView from './JobPartsGanttView'; // Import the new component

const MainRouter = () => {
  const isLoggedIn = useSelector((state) => state.selectedUserData.userLoggedIn);

  return (
    <>
      <NavigationBar isLoggedIn={isLoggedIn} />
      <Routes>
        <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/users" element={<Users />} />
        <Route path="/resourcesview" element={<ResourcesView />} />
        <Route path="/jobparts" element={<JobParts />} />
        <Route path="/mycomponent" element={<MyComponent />} />
        <Route path="/partslist" element={<PartsList />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/jobpartsganttview" element={<JobPartsGanttView />} /> // Add new route for the JobPartsGanttView
        <Route path="/login" element={isLoggedIn ? <Navigate to="/" /> : <Login />} />
      </Routes>
    </>
  );
};

export default MainRouter;
