import React from 'react';
import { Badge } from 'react-bootstrap';

const LatestWorkState = ({ workPart }) => {
  // Löydä uusin työtila
  const latestWorkState = workPart.tyonosanTyotilat.reduce((latestState, currentState) => {
    if (!latestState || new Date(currentState.muutosaika) > new Date(latestState.muutosaika)) {
      return currentState;
    }
    return latestState;
  }, null);

  if (!latestWorkState) {
    return <p>Ei tilaa</p>;
  }

  return (
        <>
          {/*console.log(latestWorkState)*/}
          <Badge bg={latestWorkState.tyotilanluokka}>{latestWorkState.tyotilannimi}</Badge>
        </>
        
  );
};

export default LatestWorkState;
