import React from 'react';
import { Form, Button, Card, Container } from 'react-bootstrap';
import { containerStyle } from '../styles/ContainerStyle';

const Login = () => {
  // Voit lisätä kirjautumislogiikan tähän

  const handleLogin = () => {
    // Kirjautumislogiikka tulee tänne
  };

  return (
    <Container style={containerStyle}>
        <Form>
            <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control type="text" placeholder="Enter your username" />
            </Form.Group>

            <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Enter your password" />
            </Form.Group>

            <Button variant="primary" type="button" onClick={handleLogin}>
            Log In
            </Button>
        </Form>
    </Container>
  );
};

export default Login;
