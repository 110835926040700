// src/features/actions/removeJobData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const removeJobData = createAsyncThunk('data/removeJobData', async (jobId) => {
  try {
    // Make an HTTP DELETE request to remove the project
    const response = await axios.delete(`${backendUrl}/tyotDeleteJob/${jobId}`);

    // Check if the backend returns a success message
    if (response.data.message === 'Työ poistettu onnistuneesti') {
      // Return the project ID to be used in the reducer
      console.log('Job deleted successfully')
      return jobId;
    } else {
      // If deletion is not successful, throw an error
      console.log('Job deletion failed.')
      throw new Error('Job deletion failed.');
    }
  } catch (error) {
    // Catch and handle errors here
    console.error('Error removing job:', error);
    throw error;
  }
});
