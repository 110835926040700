import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { removeProjectData } from '../features/actions/removeProjectData';
import { fetchProjectsData } from '../features/actions/fetchProjectsData';

const RemoveProjectButton = ({ projectId }) => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false); // Lisätään tila projektin poistamisen indikaattorille

  const handleRemoveProject = async () => {
    setIsRemoving(true); // Asetetaan indikaattori todeksi poistamisen alkaessa
    try {
      await dispatch(removeProjectData(projectId)); // Käytetään async/await odottamaan poistamistoimintoa
      dispatch(fetchProjectsData()); // Haetaan projektidata poiston jälkeen
    } catch (error) {
      console.error('Error removing project:', error);
      // Käsittele virheet tässä tarvittaessa
    } finally {
      setIsRemoving(false); // Asetetaan indikaattori takaisin epätodeksi, kun poistaminen on valmis
    }
  };

  return (
    <Button size="sm" variant="danger" onClick={handleRemoveProject} disabled={isRemoving}>
      {isRemoving ? (
        <>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Removing...
        </>
      ) : (
        'Remove Project'
      )}
    </Button>
  );
};

export default RemoveProjectButton;
