// src/features/actions/removeProjectData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const removeProjectData = createAsyncThunk('data/removeProjectData', async (projectId) => {
  try {
    // Make an HTTP DELETE request to remove the project
    const response = await axios.delete(`${backendUrl}/projektit/${projectId}`);

    // Check if the backend returns a success message
    if (response.data.message === 'Project deleted successfully') {
      // Return the project ID to be used in the reducer
      console.log('Project deleted successfully')
      return projectId;
    } else {
      // If deletion is not successful, throw an error
      console.log('Project deletion failed.')
      throw new Error('Project deletion failed.');
    }
  } catch (error) {
    // Catch and handle errors here
    console.error('Error removing project:', error);
    throw error;
  }
});
