import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const postNewResource = createAsyncThunk(
  'resources/postNewResource',
  async (resourceData, thunkAPI) => {
    try {
      const response = await axios.post(`${backendUrl}/postNewResource`, resourceData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
