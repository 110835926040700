// src/features/actions/fetchProjectsData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchProjectsData = createAsyncThunk('data/fetchProjectsData', async () => {
  const response = await axios.get(`${backendUrl}/projektitGetAll`);
  return response.data;
});
