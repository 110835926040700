import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { putUpdateResourceData } from '../../features/actions/putUpdateResourceData';
import { fetchResourcesDataById } from '../../features/actions/fetchResourcesDataById';
import { getAllParts } from '../../features/actions/getAllParts';
import { postNewResource } from '../../features/actions/postNewResource';

const UpdateResource = ({ onResourceUpdated, editedRow, editedRowResurssiid }) => {
  const dispatch = useDispatch();
  const partsData = useSelector((state) => state.data.partsData);
  const [resourceData, setResourceData] = useState({
    nimi: editedRow ? editedRow.nimi : '',
    ulosmyyntihinta: editedRow ? editedRow.ulosmyyntihinta : 0,
    kateprosentti: editedRow ? editedRow.kateprosentti : 0,
    yksikko: editedRow ? editedRow.yksikko : '',
    seuraaaikaa: editedRow ? editedRow.seuraaaikaa : false,
    ominaisuudet: editedRow ? editedRow.ominaisuudet : '',
    osat: [],
  });
  const [selectedParts, setSelectedParts] = useState([]);
  const [totalKateprosentti, setTotalKateprosentti] = useState(editedRow ? editedRow.kateprosentti : 0);
  const [resourceCreated, setResourceCreated] = useState(false);
  const [poistettavatOsat, setPoistettavatOsat] = useState([]);
  const [lisattavatOsat, setLisattavatOsat] = useState([]);
  const [updateMessage, setUpdateMessage] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Alustetaan lataustila todeksi, jotta spinner näkyy ensin

  useEffect(() => {
    // Haetaan kaikki osat
    dispatch(getAllParts());
    if (editedRow) {
      fetchData();
    } else {
      setIsLoading(false); // Jos editedRow on epätosi, asetetaan lataustila pois päältä
    }
  }, [dispatch, editedRow, editedRowResurssiid]);

  const fetchData = async () => {
    setIsLoading(true); // Asetetaan lataustila todeksi
    const fetchedData = await dispatch(fetchResourcesDataById(editedRowResurssiid));
    if (fetchedData.payload) {
      const { nimi, ulosmyyntihinta, yksikko, luokka, seuraaaikaa, ominaisuudet, resurssinosat } = fetchedData.payload;
      const selectedParts = resurssinosat.map(part => ({ value: part.osa.osaid, label: part.osa.osanimi, sisaanostohinta: part.osa.sisaanostohinta, resurssinosaid: part.resurssinosaid }));
      setResourceData({
        nimi,
        ulosmyyntihinta,
        yksikko,
        luokka,
        seuraaaikaa,
        ominaisuudet,
        osat: selectedParts
      });
      setSelectedParts(selectedParts);
    }
    setIsLoading(false); // Kun data on ladattu, asetetaan lataustila pois päältä
  };

  useEffect(() => {
    const calculateKateprosentti = (selectedParts, price) => {
      const totalKateprosentti = selectedParts.reduce((sum, part) => {
        return sum + parseFloat(part.sisaanostohinta);
      }, 0);

      const katetuotto = price - totalKateprosentti;
      const kateprosentti = (katetuotto / price) * 100;
      return kateprosentti;
    };

    const kateprosentti = calculateKateprosentti(selectedParts, resourceData.ulosmyyntihinta);
    setTotalKateprosentti(kateprosentti);
    setResourceData((prevData) => ({
      ...prevData,
      kateprosentti: kateprosentti,
      osat: selectedParts.map(part => ({ osaid: part.value, resurssinosaid: part.resurssinosaid })),
    }));
  }, [selectedParts, resourceData.ulosmyyntihinta]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Asetetaan lataustila todeksi
  
    // Luodaan uusi resurssi-objekti, joka sisältää tarvittavat tiedot
    const updatedResourceData = {
      resurssiid: editedRowResurssiid, // Varmista, että resurssiid on oikein
      nimi: resourceData.nimi,
      ulosmyyntihinta: resourceData.ulosmyyntihinta,
      kateprosentti: resourceData.kateprosentti,
      yksikko: resourceData.yksikko,
      luokka: resourceData.luokka,
      seuraaaikaa: resourceData.seuraaaikaa,
      ominaisuudet: resourceData.ominaisuudet,       
      lisattavatosat: lisattavatOsat,
      poistettavatosat: poistettavatOsat,
    };
    if (editedRow === true)
    {
      try {
        const response = await dispatch(putUpdateResourceData(updatedResourceData));
        if(response.payload) {
          setUpdateMessage(response.payload.message);
          setResourceId(response.payload.resourceId);
          setResourceCreated(true); // Oletetaan, että tämä on olemassa oleva tilamuuttuja
          if(onResourceUpdated) onResourceUpdated();
        }
      } catch (error) {
        console.error('Error updating resource:', error);
      } finally {
        setIsLoading(false); // Asetetaan lataustila falseksi
      }
    }
    else{
      try {
        const response = await dispatch(postNewResource(updatedResourceData));
        if(response.payload) {
          setUpdateMessage(response.payload.message);
          setResourceId(response.payload.resourceId);
          setResourceCreated(true); // Oletetaan, että tämä on olemassa oleva tilamuuttuja
          if(onResourceUpdated) onResourceUpdated();
        }
      } catch (error) {
        console.error('Error creating resource:', error);
      } finally {
        setIsLoading(false); // Asetetaan lataustila falseksi
      }
    }
    
  }

  const handleChange = (selectedOptions) => {
    const deletedPart = selectedParts.find(part => !selectedOptions.some(option => option.value === part.value));
    setSelectedParts(selectedOptions);
  
    if (deletedPart) {
      setPoistettavatOsat([
        ...poistettavatOsat,
        { resurssinosaid: deletedPart.resurssinosaid }
      ]);
    } else {
      if (selectedOptions.length > 0) {
        setLisattavatOsat([
          ...lisattavatOsat,
          { osaid: selectedOptions[selectedOptions.length - 1].value }
        ]);
      }
    } 
  };
  
  const options = partsData.map(part => ({ value: part.osaid, label: part.osanimi, sisaanostohinta: part.sisaanostohinta }));

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    )
  }
  
  if (resourceCreated) {
    return (
      <div>
        <h2>{updateMessage}</h2>
        <p>Resource ID: {resourceId}</p>
      </div>
    );
  }

  return (
    <div>
      <h2>{editedRow ? 'Edit Resource' : 'Create Resource'}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formResourceName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter resource name"
            value={resourceData.nimi}
            onChange={(e) => setResourceData({ ...resourceData, nimi: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourcePrice">
          <Form.Label>Price</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter price"
            value={resourceData.ulosmyyntihinta}
            onChange={(e) => setResourceData({ ...resourceData, ulosmyyntihinta: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceLuokka">
          <Form.Label>Class</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter class"
            value={resourceData.luokka}
            onChange={(e) => setResourceData({ ...resourceData, luokka: e.target.value })}
          />
        </Form.Group>        

        <Form.Group controlId="formResourceUnit">
          <Form.Label>Unit</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter unit"
            value={resourceData.yksikko}
            onChange={(e) => setResourceData({ ...resourceData, yksikko: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceFollowTime">
          <Form.Check
            type="checkbox"
            label="Follow Time"
            checked={resourceData.seuraaaikaa}
            onChange={(e) => setResourceData({ ...resourceData, seuraaaikaa: e.target.checked })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceFeatures">
          <Form.Label>Features</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter features"
            value={resourceData.ominaisuudet}
            onChange={(e) => setResourceData({ ...resourceData, ominaisuudet: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formResourceParts">
          <Form.Label>Parts</Form.Label>
          <Select
            options={options}
            isMulti
            value={selectedParts}
            onChange={handleChange}
          />

          <Button variant="secondary" onClick={() => handleChange([], resourceData.osaid)}>Add Part</Button>
        </Form.Group>

        <Form.Group controlId="formResourceKate">
          <Form.Label>Kateprosentti</Form.Label>
          <Form.Control
            type="text"
            placeholder="Kateprosentti"
            value={`${totalKateprosentti} %`}
            readOnly
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          {editedRow ? 'Update Resource' : 'Create Resource'}
        </Button>
      </Form>
    </div>
  );
};

export default UpdateResource;
