// src/components/Users.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersData } from '../features/actions/fetchUsersData';
import { Table, Spinner, Alert, Container, Row, Col } from 'react-bootstrap';

const Users = () => {
  const dispatch = useDispatch();
  const { usersData, usersStatus, error } = useSelector((state) => state.data);

  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    dispatch(fetchUsersData());
  }, [dispatch]);

  useEffect(() => {
    setSortedUsers([...usersData]);
  }, [usersData]);

  const handleSort = (key) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);

    const sorted = [...sortedUsers].sort((a, b) => {
      if (order === 'asc') {
        return a[key].localeCompare(b[key]);
      } else {
        return b[key].localeCompare(a[key]);
      }
    });

    setSortedUsers(sorted);
  };

  return (
    <Container>
      <h2>Users data from API</h2>

      {usersStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {usersStatus === 'failed' && <Alert variant="danger">Error: {error}</Alert>}
      {usersStatus === 'succeeded' && (
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('kayttajanimi')}>Käyttäjänimi</th>
                  <th onClick={() => handleSort('nimi')}>Nimi</th>
                  <th onClick={() => handleSort('osoite')}>Osoite</th>
                  <th onClick={() => handleSort('puhelinnumero')}>Puhelinnumero</th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user, index) => (
                  <tr key={index}>
                    <td>{user.kayttajanimi}</td>
                    <td>{user.nimi}</td>
                    <td>{user.osoite}</td>
                    <td>{user.puhelinnumero}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Users;
