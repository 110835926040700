// src/features/actions/fetchWorkPartsData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const fetchWorkPartsData = createAsyncThunk('data/fetchWorkPartsData', async () => {
  const response = await axios.get(`${backendUrl}/getAllJobParts`);
  return response.data;
});
