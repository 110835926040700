import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { postNewJobPart } from '../features/actions/postNewJobPart';
import { Modal, Button, Form } from 'react-bootstrap';

const AddNewJobPartButton = ( {tyoid, kayttajaid, tyotilaid} ) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nimi: 'tyonosan nimi',
    kuvaus: 'Kuvaus uudesta työn osasta',
    tyoid: tyoid,
    tyotilaid: tyotilaid,
    kayttajaid: kayttajaid
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

    console.log(tyoid, kayttajaid, tyotilaid)
  const handleSubmit = async (e) => {
    
    e.preventDefault();
    try {
      await dispatch(postNewJobPart(formData));
      console.log('Uusi työn osa luotu onnistuneesti!');
      setShowModal(false);
      // Tyhjennä lomake
      setFormData({
        nimi: 'tyonosan nimi',
        kuvaus: 'Kuvaus uudesta työn osasta',
        tyotilaid: tyotilaid,
        kayttajaid: kayttajaid
      });
    } catch (error) {
      console.error('Virhe luotaessa uutta työn osaa:', error);
    }
  };

  return (
    <>
      <Button size="sm" variant="primary" onClick={() => setShowModal(true)}>Lisää uusi työn osa</Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Lisää uusi työn osa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="nimi">
              <Form.Label>Nimi</Form.Label>
              <Form.Control type="text" name="nimi" value={formData.nimi} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="kuvaus">
              <Form.Label>Kuvaus</Form.Label>
              <Form.Control type="text" name="kuvaus" value={formData.kuvaus} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="tyotilaid">
              <Form.Label>Työtila ID</Form.Label>
              <Form.Control type="text" name="tyotilaid" value={formData.tyotilaid} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group controlId="kayttajaid">
              <Form.Label>Käyttäjä ID</Form.Label>
              <Form.Control type="text" name="kayttajaid" value={formData.kayttajaid} onChange={handleInputChange} />
            </Form.Group>
            <Button variant="primary" type="submit">Lisää</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewJobPartButton;
