import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Spinner, Alert, Table, Collapse, Button, Modal } from 'react-bootstrap';
import { fetchResourcesData } from '../../features/actions/fetchResourcesData';
import { putUpdateResourceData } from '../../features/actions/putUpdateResourceData';
import UpdateResource from './UpdateResource';

const Resources = () => {
  const dispatch = useDispatch();
  const resourcesData = useSelector((state) => state.data.resourcesData);
  const resourcesStatus = useSelector((state) => state.data.resourcesStatus);

  const [sortedResources, setSortedResources] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [openRow, setOpenRow] = useState(null);
  const [editedRow, setEditedRow] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false); // Track whether data has been loaded initially

  useEffect(() => {
    if (!isDataLoaded) {
      dispatch(fetchResourcesData());
      setIsDataLoaded(true);
    }
  }, [dispatch, isDataLoaded]);

  useEffect(() => {
    if (Array.isArray(resourcesData)) {
      setSortedResources([...resourcesData]);
    }
  }, [resourcesData]);
  
  const handleSort = (event) => {
    if (event && event.column && event.column.colId) {
      const key = event.column.colId;
      const order = sortOrder === 'asc' ? 'desc' : 'asc';
      setSortOrder(order);

      const sorted = [...sortedResources].sort((a, b) => {
        if (key === 'seuraaaikaa') {
          return order === 'asc' ? a[key] - b[key] : b[key] - a[key];
        } else {
          return order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
        }
      });

      setSortedResources(sorted);
    }
  };

  const toggleRow = (rowId) => {
    setOpenRow(openRow === rowId ? null : rowId);
  };

  const handleEdit = (rowData) => {
    setEditedRow(rowData);
    setShowEditModal(true);
  };

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleOkRes = async () => {
    if (editedRow) {
      console.log('Sending PUT request to update data...');
      dispatch(fetchResourcesData());

      //const editedData = { ...editedRow, seuraaaikaa: editedRow.seuraaaikaa === 'true' };      
    }
    else{
      console.log('Sending POST request to update data...');
      dispatch(fetchResourcesData());
    }
    setEditedRow(null);
    setShowEditModal(false);
    setShowCreateModal(false);
  };

  const handleChange = (e, columnName, rowId) => {
    const { value } = e.target;
    const newValue = columnName === 'seuraaaikaa' ? value.toLowerCase() === 'true' : value;
    setEditedRow((prevRow) => ({
      ...prevRow,
      [columnName]: newValue,
      resurssiid: rowId,
    }));
  };

  const renderDetailRow = (rowData) => (
    <tr key={`${rowData.resurssiid}-details`} className="detail-row">
      <td colSpan="9">
        <Collapse in={openRow === rowData.resurssiid}>
          <div>
            {rowData.resurssinosat.length > 0 && (
              <div>
                <h5>resurssiin {rowData.nimi} liittyvät osat:</h5>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Osa ID</th>
                      <th>Osa nimi</th>
                      <th>Sisäänostohinta</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.resurssinosat.map((resurssinosa) => (
                      <tr key={resurssinosa.resurssinosaid}>
                        <td>{resurssinosa.osa.osaid}</td>
                        <td>{resurssinosa.osa.osanimi}</td>
                        <td>{resurssinosa.osa.sisaanostohinta} €</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Collapse>
      </td>
    </tr>
  );

  const rowData = sortedResources && sortedResources.map((resource) => ({
    luokka: resource.luokka,
    nimi: resource.nimi,
    ulosmyyntihinta: resource.ulosmyyntihinta,
    yksikko: resource.yksikko,
    kateprosentti: resource.kateprosentti,
    seuraaaikaa: (typeof resource.seuraaaikaa === 'boolean') ? resource.seuraaaikaa.toString() : '',
    ominaisuudet: resource.ominaisuudet,
    resurssiid: resource.resurssiid,
    resurssinosat: resource.resurssinosat || [],
  }));

  return (
    <>
      <h2>Resources</h2>

      <Button onClick={handleCreate}>Create New Resource</Button>

      {resourcesStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {resourcesStatus === 'failed' && <Alert variant="danger">Error loading resources data</Alert>}
      {resourcesStatus === 'succeeded' && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => handleSort('luokka')}>Luokka</th>
              <th onClick={() => handleSort('nimi')}>Nimi</th>
              <th onClick={() => handleSort('ulosmyyntihinta')}>Ulosmyyntihinta</th>
              <th onClick={() => handleSort('yksikko')}>Yksikkö</th>
              <th onClick={() => handleSort('kateprosentti')}>Kateprosentti</th>
              <th onClick={() => handleSort('seuraaaikaa')}>Seuraa aikaa</th>
              <th onClick={() => handleSort('ominaisuudet')}>Ominaisuudet</th>
              <th onClick={() => handleSort('resurssiid')}>Resurssi ID</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {rowData.map((row) => (
              <React.Fragment key={row.resurssiid}>
                <tr>
                  <td onClick={() => toggleRow(row.resurssiid)}>{openRow === row.resurssiid ? 'V' : '>'}</td>
                  <td>{row.luokka}</td>
                  <td>{row.nimi}</td>
                  <td>{`${row.ulosmyyntihinta} €`}</td>
                  <td>{row.yksikko}</td>
                  <td>{`${row.kateprosentti} %`}</td>
                  <td>{row.seuraaaikaa}</td>
                  <td>{row.ominaisuudet}</td>
                  <td>{row.resurssiid}</td>
                  <td>
                    <Button onClick={() => handleEdit(row)}>Edit</Button>
                  </td>
                </tr>
                {openRow === row.resurssiid && renderDetailRow(row)}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}

    <Modal show={showEditModal && editedRow !== null} onHide={() => setShowEditModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editedRow && (
          <UpdateResource editedRow={true} editedRowResurssiid={editedRow.resurssiid} onResourceUpdated={handleOkRes} />
        )}
      </Modal.Body>
    </Modal>

    <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UpdateResource editedRow={false} onResourceUpdated={handleOkRes} />
      </Modal.Body>
    </Modal>

    </>
  );
};

export default Resources;
