import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const putUpdateResourceData = createAsyncThunk(
  'resources/putUpdateResourceData',
  async (updatedResourceData, thunkAPI) => {
    try {
      const { resurssiid, ...data } = updatedResourceData;
      const response = await axios.put(`${backendUrl}/putUpdateResource/${resurssiid}`, data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
