// src/components/AddNewProjectButton.js

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddProject from './AddProject';

const AddNewProjectButton = ({ handleAddProjectSubmit }) => {
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  const handleToggleModal = () => {
    setShowAddProjectModal(!showAddProjectModal);
  };

  const handleCloseModal = () => {
    setShowAddProjectModal(false);
  };

  const handleAddProject = (projectName) => {
    handleAddProjectSubmit(projectName); // Välitetään projektin nimi
    handleCloseModal(); // Sulkee modalin lisäämisen jälkeen
  };

  return (
    <>
      <Button size="sm" onClick={handleToggleModal}>Add New Project</Button>
      <Modal show={showAddProjectModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProject handleAddProjectSubmit={handleAddProject} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewProjectButton;
