// src/features/actions/postProjectData.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const postProjectData = createAsyncThunk('data/postProjectData', async (projectData) => {
  const response = await axios.post(`${backendUrl}/projektitPostNewProject`, projectData);
  return response.data;
});
