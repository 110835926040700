// src/components/NavigationBar.js

import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowDeveloperData } from '../features/developerDataSlice';
import SelectUserForTesting from './SelectUserForTesting';

const NavigationBar = () => {
  const location = useLocation();
  const showDeveloperData = useSelector((state) => state.developerData.showDeveloperData);
  const userLoggedIn = useSelector((state) => state.selectedUserData.userLoggedIn);
  const dispatch = useDispatch();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleToggleDeveloperData = () => {
    dispatch(toggleShowDeveloperData());
  };

  return (
    <>
      <SelectUserForTesting />
      <Navbar bg="dark" variant="dark" expand="md">
        <Container>
          <Navbar.Brand as={Link} to="/">
            TapeSnake Production Hub
          </Navbar.Brand>
          {userLoggedIn && (
            <>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                <Nav className="mr-auto">
                  <Nav.Link as={Link} to="/" className={isActive('/') ? 'active' : ''}>
                    Home
                  </Nav.Link>
                  <Nav.Link as={Link} to="/projects" className={isActive('/projects') ? 'active' : ''}>
                    Projects
                  </Nav.Link>
                  <Nav.Link as={Link} to="/resources" className={isActive('/resources') ? 'active' : ''}>
                    Resources
                  </Nav.Link>
                  <Nav.Link as={Link} to="/customers" className={isActive('/customers') ? 'active' : ''}>
                    Customers
                  </Nav.Link>
                  <Nav.Link as={Link} to="/users" className={isActive('/users') ? 'active' : ''}>
                    Users
                  </Nav.Link>
                  <Nav.Link as={Link} to="/resourceag-grid" className={isActive('/resourceag-grid') ? 'active' : ''}>
                    Resource ag-grid
                  </Nav.Link>
                  <NavDropdown title="Tests">
                    <NavDropdown.Item as={Link} to="/jobs" className={isActive('/jobs') ? 'active' : ''}>
                      Jobs
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/jobparts" className={isActive('/jobparts') ? 'active' : ''}>
                      Job Parts
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/mycomponent" className={isActive('/mycomponent') ? 'active' : ''}>
                      MyComponent
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/partslist" className={isActive('/partslist') ? 'active' : ''}>
                      Parts List
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/jobpartsganttview" className={isActive('/jobpartsganttview') ? 'active' : ''}>
                      Job Parts Gantt View
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleToggleDeveloperData}>
                      {showDeveloperData ? 'Hide JSON Data' : 'Show JSON Data'}
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
    </>
  );
};

export default NavigationBar;
