import React, { useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Container, Row, Alert } from 'react-bootstrap';
import { containerStyle } from '../styles/ContainerStyle';

const YtjSearch = () => {
  const [businessId, setBusinessId] = useState('');
  const [companyData, setCompanyData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [searchError, setSearchError] = useState('');

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://avoindata.prh.fi/tr/v1/${businessId}`);
      if (response.data.results && response.data.results.length > 0) {
        setCompanyData(response.data.results[0]);
        setSelectedAddress(null); // Nollaa valitun osoitteen haun jälkeen
        setSearchError('');

        // Jos yrityksellä on vain yksi osoite, asetetaan se automaattisesti valituksi osoitteeksi
        if (response.data.results[0].addresses.length === 1) {
          setSelectedAddress(0);
        }

        console.log(response.data.results[0]);
      } else {
        setCompanyData(null);
        setSelectedAddress(null);
        setSearchError('Company information not found.');
      }
    } catch (error) {
      console.error('Error fetching company data:', error);
      setSearchError('Error fetching company information. Please try again.');
    }
  };

  const handleAddressSelect = (index) => {
    setSelectedAddress(index);
  };

  return (
    <Container style={containerStyle}>
      <Row>
        <Col>
          <Card>
            <h2>Company Information Search</h2>
            <Row>
              <Col>
                <label>
                  Business ID:
                  <input
                    type="text"
                    value={businessId}
                    onChange={(e) => setBusinessId(e.target.value)}
                  />
                </label>
                <button onClick={handleSearch}>Search</button>
              </Col>
            </Row>
            {searchError && (
              <Alert variant="warning" className="mt-3">
                {searchError}
              </Alert>
            )}
            {companyData && !searchError && (
              <div>
                {companyData.addresses.length > 1 ? (
                  <div>
                    <p>Company has multiple addresses. Please select an address to use:</p>
                    {companyData.addresses.map((address, index) => (
                      <div key={index}>
                        <label>
                          <input
                            type="radio"
                            name="address"
                            checked={selectedAddress === index}
                            onChange={() => handleAddressSelect(index)}
                          />
                          {`${address.street}, ${address.postCode} ${address.city}`}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  // Jos yrityksellä on vain yksi osoite, näytä se automaattisesti
                  <p>Address: {companyData.addresses[0].street}, {companyData.addresses[0].postCode} {companyData.addresses[0].city}</p>
                )}
              </div>
            )}
          </Card>
        </Col>
        <Col>
          <Card>
            <h2>Company Information</h2>
            {companyData && !searchError ? (
              <>
                <h5>Company name: {companyData.name}</h5>
                <p><strong>businessId: </strong>{companyData.businessId}</p>
                <p><strong>Address: </strong>{selectedAddress !== null ? `${companyData.addresses[selectedAddress].street}, ${companyData.addresses[selectedAddress].postCode} ${companyData.addresses[selectedAddress].city}` : 'No address selected'}</p>
                <Button variant="primary">Add this company to database</Button>
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default YtjSearch;
