import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProjectProgressBar = ({ progress }) => {
  //console.log("progressbar progress:", progress);

  // Tarkistetaan, onko progress-objekti tyhjä
  const isEmpty = Object.values(progress).every(val => val === 0);

  // Jos kaikki arvot ovat 0, palautetaan "Project doesn't contain any jobs"
  if (isEmpty) {
    return <p>Project doesn't contain any jobs</p>;
  }

  // Lasketaan kokonaisprosentti arvojen perusteella
  const total = Object.values(progress).reduce((acc, val) => acc + val, 0);
  const successPercent = Math.round((progress.success / total) * 100) || 0;
  const infoPercent = Math.round((progress.info / total) * 100) || 0;
  const warningPercent = Math.round((progress.warning / total) * 100) || 0;
  const dangerPercent = Math.round((progress.danger / total) * 100) || 0;

  return (
    <ProgressBar style={{ width: '95%' }}>
      <ProgressBar variant="success" now={successPercent} label={`VALMIS (${successPercent}%)`} />
      <ProgressBar variant="info" now={infoPercent} label={`TEHTY (${infoPercent}%)`} />
      <ProgressBar variant="warning" now={warningPercent} label={`ODOTTAA ALOITTAMISTA (${warningPercent}%)`} />
      <ProgressBar variant="danger" now={dangerPercent} label={`ALOITTAMATTA (${dangerPercent}%)`} />
    </ProgressBar>
  );
};

export default ProjectProgressBar;
