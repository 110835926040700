// src/components/Resources.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchResourcesData } from '../features/actions/fetchResourcesData';

import { Container, Spinner, Alert, Table, Row, Col } from 'react-bootstrap';

const ResourcesOld = () => {
  const dispatch = useDispatch();
  const resourcesData = useSelector((state) => state.data.resourcesData);
  const resourcesStatus = useSelector((state) => state.data.resourcesStatus);

  const [sortedResources, setSortedResources] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (resourcesStatus === 'idle') {
      dispatch(fetchResourcesData());
    }
  }, [resourcesStatus, dispatch]);

  useEffect(() => {
    setSortedResources([...resourcesData]);
  }, [resourcesData]);

  const handleSort = (key) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);

    const sorted = [...sortedResources].sort((a, b) => {
      if (key === 'seuraaaikaa') {
        // Special handling for the seuraaaikaa column (boolean)
        return order === 'asc' ? a[key] - b[key] : b[key] - a[key];
      } else {
        // Default handling for other columns
        return order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
      }
    });

    setSortedResources(sorted);
  };

  return (
    <Container>
      <h2>Resources</h2>

      {resourcesStatus === 'loading' && <Spinner animation="border" variant="primary" />}
      {resourcesStatus === 'failed' && <Alert variant="danger">Error loading resources data</Alert>}
      {resourcesStatus === 'succeeded' && (
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th onClick={() => handleSort('nimi')}>Nimi</th>
                  <th onClick={() => handleSort('resurssiid')}>Resurssi ID</th>
                  <th onClick={() => handleSort('ulosmyyntihinta')}>Ulosmyyntihinta</th>
                  <th onClick={() => handleSort('kateprosentti')}>Kateprosentti</th>
                  <th onClick={() => handleSort('yksikko')}>Yksikkö</th>
                  <th onClick={() => handleSort('seuraaaikaa')}>Seuraa aikaa</th>
                  <th onClick={() => handleSort('ominaisuudet')}>Ominaisuudet</th>
                  <th onClick={() => handleSort('luokka')}>Luokka</th>
                </tr>
              </thead>
              <tbody>
                {sortedResources.map((resource) => (
                  <tr key={resource.resurssiid}>
                    <td>{resource.nimi}</td>
                    <td>{resource.resurssiid}</td>
                    <td>{resource.ulosmyyntihinta} €</td>
                    <td>{resource.kateprosentti} %</td>
                    <td>{resource.yksikko}</td>
                    <td>{resource.seuraaaikaa.toString()}</td>
                    <td>{resource.ominaisuudet}</td>
                    <td>{resource.luokka}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ResourcesOld;
