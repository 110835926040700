import React from 'react';
import { Offcanvas, Button } from 'react-bootstrap';

const SortProjects = ({ show, handleClose }) => {
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Sort Projects</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Button variant="primary" className="mb-2" block>
          Sort by Name
        </Button>
        <Button variant="secondary" className="mb-2" block>
          Sort by Deadline
        </Button>
        <Button variant="info" className="mb-2" block>
          Sort by Progress
        </Button>
        {/* Lisää tarvittaessa muita lajitteluvaihtoehtoja */}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SortProjects;
